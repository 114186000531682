import axios from "axios";

// export const SOCKET_PATH = process.env.NODE_ENV === 'production' ? 'https://apays.io/' : 'http://80.78.242.4/'
// export const SERVER = process.env.NODE_ENV === 'production' ? 'https://apays.io/backend' : 'http://80.78.242.4/backend'
export const SOCKET_PATH = process.env.NODE_ENV === 'production' ? '' : 'http://192.168.0.43/'
export const SERVER = process.env.NODE_ENV === 'production' ? '/backend' : 'http://192.168.0.43/backend'
// export const SOCKET_PATH = 'http://80.78.242.4/'
// export const SERVER = 'http://80.78.242.4/backend'
// export const SOCKET_PATH = 'https://apays.io/'
// export const SERVER = 'https://apays.io/backend'
export const notify_i = {type: 'info', autoHideDuration: 1000, anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }}
export const notify_s = {type: 'success', autoHideDuration: 1000, anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }}
export const notify_w = {type: 'warning', autoHideDuration: 1500, anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }}
export const notify_e = {type: 'error', autoHideDuration: 2000, anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }}

export function moneyFormat(val, currency='rub') {
    if (!val === undefined || val === '') return ""
    let formatter;
    try {
        formatter = new Intl.NumberFormat('en-ZA', {
            style: 'decimal',
            currencyDisplay: 'narrowSymbol',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    } catch (err) {
        formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            currencyDisplay: 'symbol',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }
    const symbol = currency === "rub" ? "₽" : "₴"
    return formatter.format(val) + " " + symbol
}

export function random(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
}

export function make_id(seed = null, length = 10) {
    if (seed == null)
        seed = Math.random()
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(random(seed + counter) * charactersLength));
        counter += 1;
    }
    return result;
}

export function cardFormat(e) {
    return e ? e.replace(/[^+\d*]/g, '').replace(/\s?/g, '').replace(/([\d|*]{4})/g, '$1 ').trim().slice(0, 19) : ""
}


export const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy')
        document.body.removeChild(textArea)
        return true
    } catch (err) {
        console.error('Unable to copy to clipboard', err)
        document.body.removeChild(textArea)
        return false
    }
}

export function timestamp() {
    return Math.ceil((new Date().valueOf() / 1000))
}

export function strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

export async function uploadFile(orderId, file, onSuccess=()=>{}, onError=()=>{}) {
    const data = new FormData();
    data.append('file', file);
    try {
        await axios.post(`${SERVER}/upload_file/${orderId}`, data,
            {
                onUploadProgress: (e) => {
                    let percent = Math.floor((100 * e.loaded) / e.total)
                    console.log('Percent', percent)
                }
            }
        );

        onSuccess()
    } catch (err) {
        console.log(err.message)
        onError()
    }
}

export function getColor(color){
    let hex_color
    switch (color) {
        case 'red':
            hex_color = "#e04326"
            break
        case 'green':
            hex_color = "#37ad1a"
            break
        case 'yellow':
            hex_color = "#e3d108"
            break
        case 'orange':
            hex_color = "#e37608"
            break
        case 'blue':
            hex_color = "#1f9fe1"
            break
        case 'purple':
            hex_color = "#9960fa"
            break
        default:
            hex_color = "#ffffff"
    }
    return hex_color
}

export function forceRefresh(){
    const refresh_btn = document.querySelectorAll('[aria-label="Refresh"]');
    if (refresh_btn.length > 0){
        refresh_btn[0].click()
    }
}
