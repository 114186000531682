import {TextField, useRecordContext} from "react-admin";
import {cardFormat, make_id} from "../../utils";
import React from "react";
import parsePhoneNumber from 'libphonenumber-js'

export function RequisitesField({source}){
    const record = useRecordContext();
    if (!record) return null;

    const recordSource = make_id();
    if (!record[source]){
        record[recordSource] = "..."
        return <TextField source={recordSource}/>
    }
    if (record[source].indexOf("https://") !== -1){
        record[recordSource] = record[source]
        return <TextField source={recordSource}/>
    }
    if (record['payment_method'] === 'spb'){
        try{
            const phoneNumber = parsePhoneNumber(record[source])
            record[recordSource] = phoneNumber.formatInternational()
        }
        catch (e) {
            record[recordSource] = record[source]
        }
    }
    else{
        record[recordSource] = cardFormat(record[source].toString())
    }
    return <TextField source={recordSource}/>
}
