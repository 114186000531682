import {useNotify} from "react-admin";
import {notify_e, notify_w, SERVER} from "../utils";
import {Button, FormControlLabel, Switch} from "@mui/material";
import {useState} from "react";

export const TogglerPostRequest = ({checked, setChecked, endpoint, target, data, okMessage, label, labelPlacement, onSuccess=()=>{}}) => {
    const notify = useNotify();
    // const [checked, setChecked] = useState(initValue)
    function showNotify(e) {

        if (e) {
            if (e["status"] === 'ok') {
                notify(okMessage, {type: 'success'});
                onSuccess()
            }
            else if (e["request_error"] === "invalid_status"){
                notify(`Ошибка. Обновите страницу и попробуйте еще раз`, notify_w);
            }

            else {
                notify(e["request_error"], notify_e);
            }
        } else {
            notify(`Ошибка`, {type: 'error'});
        }
    }
    function setStatus(result){
        showNotify(result)
        console.log(result)
    }

    function _setChecked(isChecked) {
        data.isChecked = isChecked
        setChecked(isChecked)
        fetch(`${SERVER}/admin/${endpoint}/${target}`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => (setStatus(result)))
            .catch(e => {notify(`Ошибка`, {type: 'error'}); setChecked(!isChecked)})
    }

    return (<FormControlLabel control={
            <Switch onChange={e => _setChecked(e.target.checked)} checked={checked}/>
        } label={label} labelPlacement={labelPlacement}/>

    );
};
