import React, {useEffect, useRef, useState} from "react";
import QRCodeStyling from "qr-code-styling";
import logo from "../img/apays_logo.png"

const QRCodeComponent = ({ url }) => {
  const [options, setOptions] = useState({
    width: 240,
    height: 240,
    type: 'svg',
    data: url,
    image: logo,
    margin: 10,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'Q'
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.2,
      margin: 4,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: '#5465ff',
      type: 'rounded'
    },
    backgroundOptions: {
      color: '#ffffff',
    },
    cornersSquareOptions: {
      color: '#5465ff',
      type: 'extra-rounded',
    },
    cornersDotOptions: {
      color: '#5465ff',
      type: 'dot',
    }
  });

  const qrCode = useRef(new QRCodeStyling(options)).current;
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode]);
  useEffect(() => {
    if (url) {
      qrCode.update({ ...options, data: url });
    }
  }, [url, options]);

  return (
    <div className="qr-wrapper">
      <div className="qr-container" ref={ref}></div>
    </div>
  );
};


export default QRCodeComponent;
