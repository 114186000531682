import {
    DatagridConfigurable,
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    List,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import {make_id} from "../utils";
import {Card, CardContent} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import {OrderShow} from "./OrderShow";
import {RequisitesField} from "../components/fields/RequisitesField";
import {TimestampField} from "../components/fields/TimestampField";
import {ColorMoneyField} from "../components/fields/MoneyField";

export function StatusField({source}) {
    const statusObject = {
        0: "Создан",
        1: "Ожидает реквизиты",
        2: "Ожидает оплаты",
        3: "Ожидает чек",
        4: "Ожидает проверки",
        5: "Выполнен",
        6: "Отменен",
        7: "Просрочен",
    }
    const record = useRecordContext();
    const recordSource = make_id();
    record[recordSource] = statusObject[record[source]]
    return (<TextField source={recordSource}/>)
}

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
    </TopToolbar>
);

// function ClientStatusField({source}){
//     const record = useRecordContext();
//     const recordSource = make_id();
//     record[recordSource] = !!record[source]
//     return(
//         <BooleanField source={recordSource}/>
//     )
// }
export const OrderList = () => (
    <List
        sort={{ field: 'id', order: 'DESC' }}
        aside={<FilterSidebar/>}
        actions={<ListActions/>}
    >
        <DatagridConfigurable
            rowClick="expand"
            expand={<OrderShow />}
            expandSingle
            sx={{
                '& .RaDatagrid-thead': {
                    borderLeftColor: 'transparent',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .column-text': {
                    maxWidth: '18em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <TextField source="id" label="ID" sortable={true}/>
            {/*<TextField source="sid" label="sid" sortable={true}/>*/}
            <TextField source="external_id" label="EID" sortable={false}/>
            <TextField source="client.project_name" label="Клиент" sortable={false}/>
            {/*<ColorMoneyField source="amount" label="Сумма" sortable={false}/>*/}
            <ColorMoneyField source="amount" color_name="purple" label="Заказ" sortable={false}/>
            <ColorMoneyField source="total_amount_on_currency" checkCurrency={true} color_name="yellow" label="Сумма" sortable={false}/>
            <ColorMoneyField source="owner_income" color_name="green" label="Доход" sortable={false}/>
            <ColorMoneyField source="client_income" color_name="red" label="Выплата" sortable={false}/>
            <TextField source="payment_method" label="Метод" sortable={false}/>
            <RequisitesField source="requisites" label="Реквизиты" sortable={false}/>
            {/*<ClientStatusField source="sid" label="Online" sortable={false}/>*/}
            <StatusField source="status" label="Статус" sortable={false}/>
            <TextField source="api_payment_id" label="API ID" sortable={true}/>
            <TimestampField source="created_at" label="Создан" showTime={true} sortable={false}/>
            <TimestampField source="update_at" label="Обновлен" showTime={true} sortable={false}/>
            <TextField source="webhook_send_next_time" label="WSNT" sortable={true}/>
            <TextField source="webhook_attempt" label="WA" sortable={true}/>
            <TextField source="webhook_status" label="WS" sortable={true}/>
            {/*<DeleteButton/>*/}
        </DatagridConfigurable>
    </List>
)


export const FilterSidebar = (() => {

    return (
        <Card sx={{order: -1, mr: 2, mt: 9, width: 220}}>
            <CardContent>
                <FilterLiveSearch source="q" label="Поиск"  name="search"/>

                <FilterList label="По Валюте" icon={<CurrencyExchangeIcon/>}>
                    <FilterListItem label="RUB" value={{currency: "RUB"}}/>
                    <FilterListItem label="UAH" value={{currency: "UAH"}}/>
                </FilterList>
                <FilterList label="По статусу" icon={<SyncProblemIcon/>}>
                    <FilterListItem label="Создан" value={{status: 0}}/>
                    <FilterListItem label="Ждет реквизиты" value={{status: 1}}/>
                    <FilterListItem label="Ждет оплаты" value={{status: 2}}/>
                    <FilterListItem label="Ждет чек" value={{status: 3}}/>
                    <FilterListItem label="Ждет проверки" value={{status: 4}}/>
                    <FilterListItem label="Выполнен" value={{status: 5}}/>
                    <FilterListItem label="Отменен" value={{status: 6}}/>
                    <FilterListItem label="Просрочен" value={{status: 7}}/>
                </FilterList>

            </CardContent>

        </Card>
    )
})
